.lfc-card-col{
    font-size: 6rem;
    border-top: 1px solid #AAA;
    border-right: 1px solid #AAA;
    border-bottom: 1px solid #AAA;
    border-radius: 8px;
}

.lh-0{
    line-height: 0;
    color: blue;
}

.lfc-card-col:nth-child(1){
    border-left: 1px solid #EEE;
}

.lfc-card-col .hearts,
.lfc-card-col .diamonds
{
    color: red;
    /* height: 66px; */
}

.winner .lfc-card-col .hearts,
.winner .lfc-card-col .diamonds,
.winner .lfc-card-col .clubs,
.winner .lfc-card-col .spades{
    opacity: .1;
    transition: opacity 5s linear;
}

.lfc-card-col .clubs,
.lfc-card-col .spades
{
    color: #444;
}

div .lfc-card-col.winningGroup span.idx-3{
    opacity: 1;
}

.lfc-card-col.winningGroup span.idx-3 svg{
    scale: 1.5;
    transition: scale 1s ease-in-out;
    transition-delay: 0.2s;

    animation: 0.3s ease-out infinite win-jiggle, 1s ease-out infinite win-zoom;
}

@keyframes win-jiggle {
    0%  { transform: rotate(0deg)}
    25% { transform: rotate(2deg)}
    75% { transform: rotate(-2deg)};
    100% { transform: rotate(0deg)};
}

@keyframes win-zoom {
    0%  { scale: 1}
    50%  { scale:1.5}
    100%  { scale:1}
}


.logo>figure.image.is-128x128{
    margin-left:auto;
    margin-right:auto;
    height: 76px;
}

#root.winner .logo>figure.image.is-128x128{
    height: 104px;
    scale: 60%;
    position: relative;
}

.logo .card{
    margin-bottom: 2rem;
    background-color: transparent;
}

body .column{
    padding: 0;
}

.has-background-white-tra{
    background-color: rgba (.1, .1, .1, .9);
}

.button.shuffle{
    top: 1rem;
}

#btn-dealer{
    display: none;
}

@keyframes kf-opacity {
    0%  { opacity: 0.1}
    50%  { opacity: 0.9}
    100%  { opacity: 0.1}
}

#root.winner::after{
    animation: 10s linear infinite kf-opacity;
}

@media only screen and (max-width: 624px) {
    span.suit{
        display: block;
    }

    .lfc-card-col{
        font-size: 4rem;
    }
    .lfc-card-col .suit{
        height: 66px;
    }
}

/* Dealing Cards */

.deal-start{
    position: relative;
    left: -100vmax;
    top: 50vmax;
}

.deal-to-spot{
    left: 0;
    top: 0;
    transition: left .2s, top 0.2s;
}


