html>body{
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}

#root::after {
  content: "";
  background-image: url('./img/tarot-3777647_960_720.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

@media only screen and (max-width: 624px) {
  #root::after {
    background-image: url('./img/hippie.jpg');
    opacity: 0.2;
  }
}
